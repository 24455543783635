import { useContext, useEffect, useState } from "react";
import stc from "string-to-color";
import { StageContext } from "../stageContext";

type Props = {
  bbLayerRef: React.MutableRefObject<any>;
};
export const SvgLinePortal = ({ bbLayerRef }: Props) => {
  const stageContext = useContext(StageContext);

  const [svgProperties, setSvgProperties] = useState<{
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    xa: number;
    ya: number;
    xb: number;
    yb: number;
    svgColor: string;
  }>({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
    xa: 0,
    ya: 0,
    xb: 0,
    yb: 0,
    svgColor: "transparent",
  });

  const calculateSvgLines = () => {
    if (!bbLayerRef || !bbLayerRef.current)
      setSvgProperties({
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
        xa: 0,
        ya: 0,
        xb: 0,
        yb: 0,
        svgColor: "transparent",
      });
    //CHECK Y
    let svgColor = "black"; //#1a3066
    let stageContainer = document.getElementById("stage-container")
      ?.children[0] as HTMLElement;
    //CHECK Y
    let targetBB = bbLayerRef.current.findOne(
      `#bb-${stageContext?.highlighted?.id}`
    );
    //CHECK Y
    let fromPill = document.getElementById(
      `anchor-${stageContext?.highlighted?.id}`
    ) as HTMLElement;
    //CHECK Y

    let resultList = document.getElementById("entitiesList") as HTMLElement;

    if (fromPill) {
      svgColor = stc(stageContext?.highlighted?.tag);
    }
    let galleryDiv = document.getElementById(`gallery`);
    let sidebarDiv = document.getElementById(`sidebar`);
    let header = document.getElementsByClassName(
      "header-container"
    )[0] as HTMLElement;

    //CHECK Y
    if (
      !fromPill ||
      !targetBB ||
      !galleryDiv ||
      !sidebarDiv ||
      !stageContainer ||
      !resultList
    ) {
      setSvgProperties({
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
        xa: 0,
        ya: 0,
        xb: 0,
        yb: 0,
        svgColor: "transparent",
      });
      return;
    }

    let bbAttrs = targetBB.getClientRect();

    let pillLeftWall = {
      x:
        fromPill.getBoundingClientRect().x -
        galleryDiv.getBoundingClientRect().width -
        sidebarDiv.getBoundingClientRect().width,
      y: fromPill.getBoundingClientRect().top,
    };

    if (pillLeftWall.y < 160 || pillLeftWall.y > resultList.getBoundingClientRect().bottom) {
      setSvgProperties({
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
        xa: 0,
        ya: 0,
        xb: 0,
        yb: 0,
        svgColor: "transparent",
      });
      return;
    }

    let x1, x2, y1, y2;

    x1 = stageContainer.offsetLeft + bbAttrs.x + bbAttrs.width;

    if (x1 < stageContainer.offsetLeft) x1 = stageContainer.offsetLeft;
    else if (x1 > pillLeftWall.x) x1 = pillLeftWall.x - 22;

    y1 = stageContainer.offsetTop + bbAttrs.y + bbAttrs.height / 2;
    if (y1 < stageContainer.offsetTop) y1 = stageContainer.offsetTop;
    else if (y1 > stageContainer.offsetHeight + stageContainer.offsetTop)
      y1 = stageContainer.offsetHeight + stageContainer.offsetTop;
    x2 = pillLeftWall.x;
    y2 = pillLeftWall.y + 18;
    let res = {
      x1: x1,
      x2: x2,
      y1: y1,
      y2: y2,
      xa: x2 - 100,
      ya: y2,
      xb: x2 - 100,
      yb: y1,
      svgColor: svgColor,
    };
    setSvgProperties(res);
  };

  useEffect(() => {
    calculateSvgLines();
  }, [stageContext?.highlighted]);

  if (stageContext?.highlighted === null || stageContext?.highlighted.id! < 0)
    return;

  return (
    <>
      {
        <div className="absolute h-screen w-screen z-10 top-0 left-0 pointer-events-none">
          <svg
            id="svg"
            className="absolute h-screen w-screen z-10 top-0 left-0 pointer-events-none"
          >
            {Math.abs(svgProperties.y2 - svgProperties.y1) >= 100 ? (
              <>
                <line
                  className="stroke-[3]"
                  id="line"
                  x1={svgProperties.x2}
                  y1={svgProperties.y2}
                  x2={svgProperties.xa - 1}
                  y2={svgProperties.y2}
                  stroke={svgProperties.svgColor}
                />
                <line
                  className="stroke-[3]"
                  id="line"
                  x1={svgProperties.xa}
                  y1={svgProperties.ya}
                  x2={svgProperties.x1}
                  y2={svgProperties.y1}
                  stroke={svgProperties.svgColor}
                />
              </>
            ) : (
              <>
                <line
                  className="stroke-[3]"
                  id="line"
                  x1={svgProperties.x1}
                  y1={svgProperties.y1}
                  x2={svgProperties.x2}
                  y2={svgProperties.y2}
                  stroke={svgProperties.svgColor}
                />
              </>
            )}
          </svg>
        </div>
      }
    </>
  );
};
