import axios from "axios";
import { fetchOrg, fetchUser, fetchToken } from "../auth/auth";
import axiosInstance from "../auth/axiosInterceptors";

export async function getHealthCheck() {
    try {
        const res = await axios.get(`${window.configWebApp.REACT_APP_API_FRONTEND_SERVER}/auth/users/whoami`,
            {
                headers: {
                    Authorization: 'Bearer ' + fetchToken()['access_token']
                }
            }
        )
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}


export async function getUserInfo() {
    try {
        const res = await axiosInstance.get(`/auth/users/whoami`)
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function fetchInference(file) {
    try {
        const formData = new FormData();
        formData.append('file', file)
        formData.append('prebuilt_api_name', 'id_card_ita')

        const res = await axiosInstance.post(`/prebuilt/inference`,
            formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function listAllModels(page, limit = 50) {
    try {
        let params = {
            "owner": fetchOrg(),
            "page": page,
            "limit": limit,
            "sort": "descending"
        }
        const res = await axiosInstance.get(`/models-catalog`, { params }
        )

        return res.data;
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function addUseCase(name, description, model) {

    let labels = {}

    Object.keys(model.labels).forEach((key) => {
        labels[key] = { 'tag_alias': model.labels[key].tag_alias }
    })

    const bodyData = JSON.stringify({
        "name": name,
        "description": description,
        "owner": fetchOrg(),
        "model": model.public_id,
        "labels": labels
    })

    const res = await axiosInstance.post('/use-cases',
        bodyData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })

    return res.data;

}

export async function getUseCaseDeployStatus(resourceID) {
    try {

        const res = await axiosInstance.get(`/use-cases/${resourceID}/deploy`, {
            headers: { 'Content-Type': 'application/json' }
        })

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function getUseCase(usecaseID) {

    const params = { "use_case_id": usecaseID }

    const res = await axiosInstance.get(`/use-cases/${usecaseID}`, {
        params,
        headers: { 'Content-Type': 'application/json' }
    })

    return res.data;

}

export async function listUserUseCases(page = 0, limit = 50) {
    let params = {
        "page": page,
        "limit": limit,
        "sort": "descending"
    }
    try {
        let org = fetchOrg()
        const res = await axiosInstance.get(`/user-resources/${org}/use-cases`, { params })

        return res.data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function addDocument(usecaseID, file) {

    const formData = new FormData();
    formData.append('file', file)

    const res = await axiosInstance.post(`/use-cases/${usecaseID}/documents`,
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })

    return res;

}

export async function listAllDocuments(usecaseID, revision_status, page, limit) {

    let queryParams = {}

    if (revision_status !== '')
        queryParams['revision_status'] = revision_status
    if (page !== 0)
        queryParams['page'] = page
    if (limit)
        queryParams['limit'] = limit
    try {
        const res = await axiosInstance.get(`/use-cases/${usecaseID}/documents`, {
            params: queryParams
        })

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function listAllDocumentsDateRange(usecaseID, revision_status, from_date, to_date, page, limit) {

    let queryParams = {}

    if (revision_status !== '')
        queryParams['revision_status'] = revision_status
    if (page !== 0)
        queryParams['page'] = page
    if (limit)
        queryParams['limit'] = limit
    if (from_date)
        queryParams['from_date'] = from_date
    if (to_date)
        queryParams['to_date'] = to_date
    try {
        const res = await axiosInstance.get(`/use-cases/${usecaseID}/documents`, {
            params: queryParams
        })

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function getDocument(usecaseID, documentID) {
    const res = await axiosInstance.get(`/use-cases/${usecaseID}/documents/${documentID}`, {
        headers: { 'Content-Type': 'application/json' }
    })

    return res.data;

}

export async function getFileInference(usecaseID, documentID, fileID) {

    const res = await axiosInstance.get(`/use-cases/${usecaseID}/documents/${documentID}/results/${fileID}`, {
        headers: { 'Content-Type': 'application/json' }
    })

    return res;
}

export async function getMonitorUseCasePlan(usecaseID) {
    try {

        const res = await axiosInstance.get(`/monitor/use-case-plan/${usecaseID}`, {
            headers: { 'Content-Type': 'application/json' }
        })

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function getMonitorUseCaseAnalytics(usecaseID, group_by = "MONTH") {

    try {

        const res = await axiosInstance.get(`/analytics/${usecaseID}`, {
            params: { group_type: group_by },
            headers: { 'Content-Type': 'application/json' }
        })

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function updateUseCaseInfo(usecaseID, newName, newDescription) {
    try {

        const bodyData = {
            "name": newName,
            "description": newDescription,
        }

        const res = await axiosInstance.put(`/use-cases/${usecaseID}`, bodyData)

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function deleteUseCase(usecaseID) {

    const res = await axiosInstance.delete(`/use-cases/${usecaseID}`)

    return res;

}

export async function getOrganization() {
    try {

        let org = fetchOrg()
        const res = await axiosInstance.get(`/organization/info`, { params: { org_name: org } })

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function getOrganizationPlans() {
    try {

        let org = fetchOrg()
        const res = await axiosInstance.get(`/monitor/organization-plan`, { params: { org: org } })

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function registerOrganization(company_name, company_type, company_size, vat, invoice_email, email = null, phone = null) {

    let user = fetchUser()

    const bodyData = JSON.stringify({
        "owner": user,
        "company_name": company_name,
        "company_type": company_type,
        "company_size": company_size,
        "vat": vat,
        "invoice_email": invoice_email,
        "email": email,
        "phone": phone,
    })

    return await axiosInstance.post(`/organization/register`,
        bodyData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
}

export async function editPredictedField(use_case_id, document_id, file_id, newField) {
    try {

        const bodyData = JSON.stringify(newField)

        const res = await axiosInstance.post(`/use-cases/${use_case_id}/documents/${document_id}/results/${file_id}/edit`,
            bodyData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

        return res.data;

    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function updateDocumentRevisionStatus(use_case_id, document_id, revision_status) {

    try {
        const res = await axiosInstance.put(`/use-cases/${use_case_id}/documents/${document_id}/revision/${revision_status}`)
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function exportDocument(use_case_id, document_id, format = 'json') {
    try {
        const res = await axiosInstance.post(`/use-cases/${use_case_id}/documents/${document_id}/export`,
            {
                params: {
                    export_format: format
                }
            })

        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error.message;
        } else {
            return 'An unexpected error occurred';
        }
    }
}

export async function updatePassword(current_password, new_password, confirm_password) {

    const bodyData = JSON.stringify({
        "current_password": current_password,
        "new_password": new_password,
        "confirm_password": confirm_password
    })


    const res = await axiosInstance.post(`/user/password/update`,
        bodyData,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })


    return res.data;
}

export async function deleteDocument(use_case_id, document_id) {

    try {
        const res = await axiosInstance.delete(`/use-cases/${use_case_id}/documents/${document_id}`)

        return res.data;
    } catch (e) {
        return e
    }
}

export async function getResourceJobs(resource_type, resource_id) {
    try {
        const res = await axiosInstance.get(`/resource-jobs/${resource_type}/${resource_id}`)

        return res.data;
    } catch (e) {
        return e
    }
}

export async function getBackgroundTask(job_id) {
    try {
        const res = await axiosInstance.get(`/background-task/${job_id}`)

        return res.data;
    } catch (e) {
        return e
    }
}

export async function getUseCasePolicy(use_case_id) {
    try {
        const res = await axiosInstance.get(`/use-cases/${use_case_id}/policy`)

        return res.data;
    } catch (e) {
        return e
    }
}

export async function updateUseCasePolicyActivation(use_case_id, enable) {
    try {
        const res = await axiosInstance.put(`/use-cases/${use_case_id}/policy`, null, {
            params: {
                enable: enable
            }
        })

        return res.data;
    } catch (e) {
        return e
    }
}

export async function addNewPolicyRule(use_case_id, rule_name, rule_class, rule_args, rule_apply_on_pages, rule_index = null) {
    try {

        if (rule_apply_on_pages) {
            const [startRange, endRange] = rule_apply_on_pages.split("-")
            if (startRange === endRange) {
                rule_apply_on_pages = startRange
            }
        }

        let newRule = {
            "rule_name": rule_name,
            "rule_class": rule_class,
            "rule_args": rule_args,
            "rule_apply_on_pages": rule_apply_on_pages,
        }

        if (rule_index !== null)
            newRule['rule_index'] = rule_index

        const res = await axiosInstance.post(`/use-cases/${use_case_id}/policy/policy-rule`, newRule, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        return res.data;
    } catch (e) {
        return e
    }
}

export async function deletePolicyRule(use_case_id, rule_index) {
    const res = await axiosInstance.delete(`/use-cases/${use_case_id}/policy/policy-rule/${rule_index}`)
    return res;
}

export async function getServiceTokens(use_case_id) {
    const res = await axiosInstance.get(`/service-token-auth/${use_case_id}`, {
        headers: {
            "Content-Type": 'application/json'
        }
    })

    return res
}

export async function createServiceToken(use_case_id, name) {
    const res = await axiosInstance.post(`/service-token-auth/${use_case_id}`, {
        'name': name
    })

    return res
}

export async function deleteServiceToken(use_case_id, token) {
    const res = await axiosInstance.delete(`/service-token-auth/${use_case_id}/token/${token}`, {
        headers: {
            "Content-Type": 'application/json'
        }
    })

    return res
}

export async function addSimpleEntity(use_case_id, document_id, file_id, entity_label, entity) {
    const res = await axiosInstance.post(`/use-cases/${use_case_id}/documents/${document_id}/results/${file_id}/entities/simple/${entity_label}`,
        {
            'bounding_boxes': entity['bounding_boxes'].length > 0 ? [entity['bounding_boxes']] : [],
            'text': entity['text'],
            'confidence': entity['confidence']
        },
    )

    return res
}

export async function updateSimpleEntity(use_case_id, document_id, file_id, entity_label, entity) {
    const res = await axiosInstance.put(`/use-cases/${use_case_id}/documents/${document_id}/results/${file_id}/entities/simple/${entity_label}`,
        {
            'entity_id': entity['id'],
            'bounding_boxes': entity['bounding_boxes'].length > 0 ? [entity['bounding_boxes']] : [],
            'text': entity['text'],
        },
    )

    return res
}

export async function deleteSimpleEntity(use_case_id, document_id, file_id, entity_label, entity_id) {
    const res = await axiosInstance.delete(`/use-cases/${use_case_id}/documents/${document_id}/results/${file_id}/entities/simple/${entity_label}/${entity_id}`)
    return res
}

export async function addAggregatedEntity(use_case_id, document_id, file_id, entity_label, entity) {
    const res = await axiosInstance.post(`/use-cases/${use_case_id}/documents/${document_id}/results/${file_id}/entities/aggregated/${entity_label}`,
        {
            'aggregated_fields': entity
        },
    )

    return res
}

export async function updateAggregatedEntity(use_case_id, document_id, file_id, entity_label, entity, entity_id) {
    const res = await axiosInstance.put(`/use-cases/${use_case_id}/documents/${document_id}/results/${file_id}/entities/aggregated/${entity_label}`,
        {
            'aggregated_fields': entity,
            'entity_id': entity_id,
        },
    )

    return res
}



export async function deleteAggregatedEntity(use_case_id, document_id, file_id, entity_label, entity_id) {
    const res = await axiosInstance.delete(`/use-cases/${use_case_id}/documents/${document_id}/results/${file_id}/entities/aggregated/${entity_label}/${entity_id}`)
    return res
}

export async function getModel(model_id) {
    const res = await axiosInstance.get(`/models-catalog/${model_id}`)
    return res
}