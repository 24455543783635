import { Stage } from "konva/lib/Stage";
import { createContext, useMemo, useRef, useState } from "react";
interface ContextProps {
  annotationRect: any;
  setAnnotationRect: React.Dispatch<React.SetStateAction<any>>;
  stageRef: React.MutableRefObject<Stage | null>;
  highlighted: { id: number; tag: string; from: string } | null;
  setHighlighted: React.Dispatch<
    React.SetStateAction<{ id: number; tag: string; from: string } | null>
  >;
  selectedText: string;
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
  zoomValue: number;
  setZoomValue: React.Dispatch<React.SetStateAction<number>>;
  scrollToPill: (id: number, tag: string) => void;
}

export const StageContext = createContext<ContextProps | null>(null);

export const StageProvider = (props: any) => {
  const stageRef = useRef<Stage>(null);
  const [annotationRect, setAnnotationRect] = useState<any>();

  const [highlighted, setHighlighted] = useState<{
    id: number;
    tag: string;
    from: string;
  } | null>(null);
  const [selectedText, setSelectedText] = useState<string>("");
  const [zoomValue, setZoomValue] = useState<number>(0.5);

  const scrollToPill = (id: number, tag: string) => {
    let list = document.getElementById("entitiesList") as HTMLElement;
    //if from pill is not into visible list, then scroll
    let fromPill = document.getElementById(`anchor-${id}`) as HTMLElement;
    if (fromPill) {
      if (list.children[0].id === "simple") {
        //simple
        if (
          fromPill.getBoundingClientRect().top >
            list?.getBoundingClientRect().bottom ||
          fromPill.getBoundingClientRect().bottom <
            list?.getBoundingClientRect().top
        ) {
          const scrollInterval = setInterval(() => {
            const fromPillRect = fromPill.getBoundingClientRect();
            const listRect = list.getBoundingClientRect();
            if (
              fromPillRect.top < listRect.top ||
              fromPillRect.bottom > listRect.bottom
            ) {
              list.scrollBy({
                top:
                  fromPillRect.top -
                  listRect.top -
                  list.clientHeight / 2 +
                  fromPill.clientHeight / 2,
                behavior: "smooth",
              });
            } else {
              clearInterval(scrollInterval);
            }
          }, 100);
        }
      } else {
        const scrollInterval = setInterval(() => {
          const fromPillRect = fromPill.getBoundingClientRect();
          const listRect = list.getBoundingClientRect();
          if (
            fromPillRect.top < listRect.top ||
            fromPillRect.bottom > listRect.bottom
          ) {
            list.scrollBy({
              top:
                fromPillRect.top -
                listRect.top -
                list.clientHeight / 2 +
                fromPill.clientHeight / 2,
              behavior: "smooth",
            });
          } else {
            clearInterval(scrollInterval);
          }
        }, 100);
      }
    }
  };

  const providerValue = useMemo(
    () => ({
      stageRef,
      annotationRect,
      setAnnotationRect,
      highlighted,
      setHighlighted,
      selectedText,
      setSelectedText,
      zoomValue,
      setZoomValue,
      scrollToPill,
    }),
    [
      stageRef,
      annotationRect,
      highlighted,
      selectedText,
      zoomValue,
      setZoomValue,
    ]
  );

  return (
    <StageContext.Provider value={providerValue}>
      {props.children}
    </StageContext.Provider>
  );
};
