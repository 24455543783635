import eyeClosed from 'assets/form/eyeClosed.svg';
import eyeOpened from 'assets/form/eyeOpened.svg';
import { resetPassword } from 'auth/auth';
import { Button } from 'components/button/Button';
import { useNotifications } from 'components/notificationSystem/notificationContext';
import React, { useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

const logoSRC = "https://assets-global.website-files.com/64245f4bdde6c4515b9ec081/643eca2362c1b771f19be634_Frame%2015.png";


export const ResetPasswordRoute = () => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()

    const [showPassword, setShowPassword] = React.useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')

    const [basicInfo, setBasicInfo] = React.useState({})

    const navigate = useNavigate()

    const [navigateToLogin, setNavigateToLogin] = React.useState(false)


    useEffect(() => {
        if (navigateToLogin) navigate('/login', { replace: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateToLogin])

    const notification = useNotifications()
    const initializeInfo = React.useCallback(() => {
        let code = searchParams.get('code')
        let email = searchParams.get('email')

        if (!code || !email) {
            notification.addNotification({
                title: 'Reset Password Error',
                message: 'Reset request not valid',
                severity: 'ERROR',
                timeout: 3000
            })
            setNavigateToLogin(true)

            return;
        }

        setBasicInfo({ code: code, email: email })
    }, [navigate, searchParams])

    React.useEffect(() => {
        initializeInfo()
    }, [initializeInfo])

    React.useEffect(() => {
        if (document.getElementById('new-password-length')) {
            document.getElementById('new-password-length').textContent = ''
        }

        if (document.getElementById('confirm-password-match')) {
            document.getElementById('confirm-password-match').textContent = ''
        }

    }, [password, confirmPassword])

    const validateUpdatePasswordForm = () => {

        if (password.length < 10) {
            document.getElementById('new-password-length').textContent = 'Must be at least 10 chars long'
            return false;
        }

        if (confirmPassword !== password) {
            document.getElementById('confirm-password-match').textContent = 'Must match with the new password'
            return false;
        }

        return true;
    }

    const handleResetPassword = async () => {
        if (!validateUpdatePasswordForm()) return;
        await resetPassword(basicInfo.email, password, confirmPassword, basicInfo.code).then((e) => {

            notification.addNotification({
                title: 'Success',
                message: 'New password set. Use that to login.',
                severity: 'SUCCESS',
                timeout: 3000
            })
            setNavigateToLogin(true)

            return;

        }).catch((e) => {

            notification.addNotification({
                title: 'Reset Password Error',
                message: e.response.data.detail,
                severity: 'ERROR',
                timeout: 3000
            })
            setNavigateToLogin(true)

        })
    }

    return (
        <div className="login-page">
            <div className='register-success'>Account successfully registered!</div>
            <div className='login-error'>Invalid username or password </div>
            <div className='resetpsw-container'>
                <div className="resetpsw-header">
                    <img src={logoSRC} alt="MyBiros" />
                </div>
                <div className="flex flex-col items-center px-10 gap-6">
                    <div className="login-title w-full">
                        Reset password
                    </div>
                    <form className="flex flex-col gap-16" onSubmitCapture={(e) => {
                        handleResetPassword()
                        e.preventDefault()
                    }}>
                        <div className="flex flex-row gap-10">
                            <div className='flex flex-col'>
                                <label className='font-light'>New Password</label>
                                <div className='relative flex flex-row'>
                                    <input type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} value={password} className='w-[380px] h-[50px] rounded border ps-5 font-normal' />
                                    <img src={showPassword ? eyeClosed : eyeOpened} className='relative w-7 z-10 -ms-10' alt='Show Password' onClick={() => setShowPassword(!showPassword)} />
                                    <span id='new-password-length' className='absolute left-1 -bottom-5 text-red-500'></span>
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <label className='font-light'>Confirm new password</label>
                                <div className='relative flex flex-row'>
                                    <input type={showConfirmPassword ? 'text' : 'password'} onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} className='w-[380px] h-[50px] rounded border ps-5 font-normal' />
                                    <img src={showConfirmPassword ? eyeClosed : eyeOpened} className='relative w-7 z-10 -ms-10' alt='Show Password' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                    <span id='confirm-password-match' className='absolute left-1 -bottom-5 text-red-500'></span>
                                </div>

                            </div>
                        </div>
                        <div className='w-full flex flex-row justify-end'>
                            <Button type='submit' rounded disabled={password === '' || confirmPassword === ''}>Reset Password</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}