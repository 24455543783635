import { AddSimpleAnnotationSVG } from "assets/canvas/AddSimpleAnnotationSVG";
import { CircleCancel } from "assets/customButtons/CircleCancel";
import { TrashSVG } from "assets/usecase/TrashSVG";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";

import { StageContext } from "../stageContext";
import { Dict, formatText, Label, Line } from "./aggregatedAnnotationPanel";
import { CreateSimpleEntityPanel } from "./createSimpleEntityPanel";

type Alert = {
  title: string;
  description: string;
  onConfirm: () => any;
  showCustomAlert: boolean;
};

type Props = {
  line: Line | null;
  lines: Line[];
  aggregationType: Label;
  labels: Dict<Label>;
  closeUpdateFlow: () => void;
  entities: Dict<any>;
  handleUpdateAggregatedEntity: (line: Line, fields: Array<any>) => void;
  handleDeleteLine: (line: Line) => Promise<void>;
  handleCreateNewAggregatedEntity: (aggregatedFields: any[]) => Promise<void>;
  createMode: boolean;
  closeCreateMode: () => void;
  setAddSimpleAnnotationMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewAnnotation: (entity: any) => Promise<any>;
  setAlertData: React.Dispatch<React.SetStateAction<Alert>>;
};

export const UpdateAggregatedEntityPanel = ({
  line,
  lines,
  aggregationType,
  labels,
  closeUpdateFlow,
  entities,
  handleUpdateAggregatedEntity,
  handleCreateNewAggregatedEntity,
  createMode,
  closeCreateMode,
  setAddSimpleAnnotationMode,
  handleAddNewAnnotation,
  setAlertData,
}: Props) => {
  const context = useContext(StageContext);

  const [aggregatedFields, setAggregatedFields] = useState<Array<any>>([]);

  const getAvailableEntities = (labelToEdit: string) => {
    let newListToShow: Array<any> = [];
    let temp: Array<any> = [];
    for (let x in entities) {
      for (let z in entities[x]) {
        if (x === labelToEdit) {
          //  let check = lines.find(l => l.aggregated_fields[])
          let check = lines.find(
            (y) =>
              y.aggregated_fields[x] !== null &&
              y.aggregated_fields[x]!.id === entities[x][z].id
          );
          if (!check)
            temp = [
              ...temp,
              {
                value: [x, entities[x][z]],
                label: (
                  <div
                    id={`anchor-${entities[x][z].id}`}
                    className="flex flex-row gap-2 items-center h-4"
                    onMouseOverCapture={(e) => {
                      context?.setHighlighted({
                        id: entities[x][z].id,
                        tag: entities[x][z].tag,
                        from: "pill",
                      });
                    }}
                    onMouseOutCapture={(e) => {
                      context?.setHighlighted(null);
                    }}
                  >
                    {entities[x][z].text}
                  </div>
                ),
              },
            ];
        }
      }
      newListToShow = [...newListToShow, ...temp];
      temp = [];
    }

    if (line) {
      if (line.aggregated_fields[labelToEdit])
        temp = [
          ...temp,
          {
            value: [labelToEdit, line.aggregated_fields[labelToEdit]],
            label: (
              <div
                id={`anchor-${line.aggregated_fields[labelToEdit]!.id}`}
                className="flex flex-row gap-2 items-center h-4"
                onMouseOverCapture={(e) => {
                  context?.setHighlighted({
                    id: line.aggregated_fields[labelToEdit]!.id,
                    tag: labelToEdit,
                    from: "pill",
                  });
                }}
                onMouseOutCapture={(e) => {
                  context?.setHighlighted(null);
                }}
              >
                {line.aggregated_fields[labelToEdit]!.text}
              </div>
            ),
          },
        ];

      newListToShow = [...newListToShow, ...temp];
      temp = [];
    }

    newListToShow = [
      ...newListToShow,
      {
        value: [labelToEdit, { id: -1, text: "--- Empty ---" }],
        label: "--- Empty ---",
      },
    ];

    return newListToShow;
  };

  useEffect(() => {
    let newList = [];
    let temp: Array<any> = [];
    let keys = aggregationType.tag_aggregation?.aggregation_fields;
    if (!keys) return;
    for (let x of keys) {
      temp = line ? [x, line.aggregated_fields[x]] : [x, null];
      newList.push(temp);
    }
    setAggregatedFields(newList);
  }, [aggregationType]);

  const saveFieldToUpdate = (field: any) => {
    let fieldToSave = field.value[1];
    let key = field.value[0];

    let oldFields = aggregatedFields;
    let index = oldFields.findIndex((x) => x[0] === key);
    oldFields[index][1] = fieldToSave;

    setAggregatedFields([...oldFields]);
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: "30px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };

  const [labelToAdd, setLabelToAdd] = useState<{
    label: string;
    tag_alias: string;
  } | null>(null);

  const handleCreateSimpleMode = () => {
    setAddSimpleAnnotationMode(false);
    setLabelToAdd(null);
  };

  const addThenSelectAnnotation = async (entity: any) => {
    await handleAddNewAnnotation(entity).then((res) => {
      setLabelToAdd(null);
      saveFieldToUpdate({ label: <></>, value: [entity.label, res.data] });
    });
  };

  return (
    <div className="h-fit flex flex-col w-full border rounded-myb">
      {labelToAdd && (
        <CreateSimpleEntityPanel
          setCreateMode={handleCreateSimpleMode}
          createMode={labelToAdd ? true : false}
          setAddSimpleAnnotationMode={setAddSimpleAnnotationMode}
          handleAddNewAnnotation={addThenSelectAnnotation}
          setAlertData={setAlertData}
          selectedLabel={labelToAdd}
        />
      )}
      <div
        style={{
          display: labelToAdd ? "none" : "block",
        }}
      >
        <div className="px-5 bg-[#EAEBFB] h-8 rounded-t-myb border-[#EAEBFB] flex items-center justify-between text-[16px] font-semibold">
          <div>{formatText(aggregationType?.tag_alias)}</div>
          <div className="flex h-full gap-2">
            {" "}
            <div
              className="!w-12 h-full flex justify-center items-center !border rounded-myb bg-white border-slate-300 hover:bg-green-300 hover:border-green-500 !transition-all duration-700"
              onClick={() => {
                context?.setHighlighted(null);
                if (createMode === true) {
                  //if every item is the type of [key, null] then dont create anything
                  let check = aggregatedFields.every((x) => x[1] === null);
                  if (!check) {
                    handleCreateNewAggregatedEntity(aggregatedFields);
                  }
                }
                if (line) {
                  let check = aggregatedFields.every(
                    (x) => x[1] === null || x[1].id === -1
                  );
                  if (!check) {
                    handleUpdateAggregatedEntity(line, aggregatedFields);
                  }
                }
              }}
            >
              Save
            </div>
            <div
              className="!w-8 h-full flex justify-center items-center !border rounded-myb bg-white border-slate-300 hover:bg-red-300 hover:border-red-500 !transition-all duration-700"
              onClick={() => {
                context?.setHighlighted(null);
                if (createMode === true) {
                  closeCreateMode();
                } else {
                  closeUpdateFlow();
                }
              }}
            >
              {createMode === true ? (
                <>
                  {createMode === true ? (
                    <TrashSVG width={22} height={22} />
                  ) : (
                    <CircleCancel width={22} height={22} />
                  )}
                </>
              ) : (
                <CircleCancel width={22} height={22} />
              )}
            </div>
          </div>
        </div>
        <div className=" border border-[#EAEBFB] rounded-e-myb rounded-b-myb w-full">
          {aggregatedFields.length > 0 &&
            aggregatedFields.map(([key, field]) => (
              <div className=" !w-full h-10 flex flex-row px-5 items-center border-b cursor-pointer">
                <div className="!font-semibold w-1/3">
                  {formatText(labels[key]?.tag_alias)}
                </div>
                <div className=" text-[14px] w-2/3 grid grid-cols-[auto,20px] gap-2">
                  <Select
                    id={key}
                    className=" whitespace-nowrap"
                    options={getAvailableEntities(key)}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                    value={getAvailableEntities(key).find((x) => {
                      if (!field) return null;
                      return x.value[1].id === field.id;
                    })}
                    onChange={(e) => {
                      context?.setHighlighted(null);
                      saveFieldToUpdate(e);
                    }}
                    defaultValue={() => {
                      let temp = getAvailableEntities(key).find(
                        (x) =>
                          x.value[1].id === line?.aggregated_fields[key]?.id
                      );
                      return temp;
                    }}
                  ></Select>

                  <div
                    className="block m-auto"
                    onClick={() => {
                      context?.setHighlighted(null);
                      setLabelToAdd({
                        label: key,
                        tag_alias: labels[key]!.tag_alias,
                      });
                    }}
                  >
                    <AddSimpleAnnotationSVG width={25} height={25} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
