import { AddSimpleAnnotationSVG } from "assets/canvas/AddSimpleAnnotationSVG";
import { Button } from "components/button/Button";
import { FilterPill } from "components/filterPill/filterPill";
import { ResultPill } from "components/resultPill/resultPill";
import { useContext, useEffect, useRef, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { CreateSimpleEntityPanel } from "./createSimpleEntityPanel";
import { StageContext } from "../stageContext";

type Dict = {
  [key: string]: any;
};

type Alert = {
  title: string;
  description: string;
  onConfirm: () => any;
  showCustomAlert: boolean;
};

type Props = {
  modelOrder?: Array<string> | null;
  labels: Dict | null;
  inference: Dict;
  unusedLabels: Array<any>;
  handleEditField: (
    label: string,
    newField: string,
    value_index?: number
  ) => {};
  setDefaultAddLabel: React.Dispatch<React.SetStateAction<string>>;
  setAddSimpleAnnotationMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewAnnotation: (entity: any) => Promise<void>;
  handleEditAnnotation: (entity: any) => Promise<void>;
  handleDeleteAnnotation: (entity: any) => Promise<void>;
  setEditSimpleAnnotation: React.Dispatch<React.SetStateAction<any>>;
  setAlertData: React.Dispatch<React.SetStateAction<Alert>>;
};
export const SimpleAnnotationPanel = ({
  modelOrder = null,
  labels,
  inference,
  unusedLabels,
  setDefaultAddLabel,
  setAddSimpleAnnotationMode,
  handleAddNewAnnotation,
  handleEditAnnotation,
  handleDeleteAnnotation,
  setEditSimpleAnnotation,
  setAlertData,
}: Props) => {
  const [createMode, setCreateMode] = useState(false);
  const [aggregationKey, setAggregationKey] = useState<string | null>(null);
  useEffect(() => {
    if (!labels) return;

    let alias = null;
    for (let key in labels) {
      if (labels[key]?.tag_aggregation !== null) {
        alias = labels[key].tag_alias;
        break;
      }
    }
    setAggregationKey(alias);
  }, [labels]);

  const [editField, setEditField] = useState<{
    label: string;
    tag_alias: string;
    "bounding-boxes": any;
    id: number;
    text: string;
  } | null>(null);

  const [filter, setFilter] = useState<
    "all" | "single" | "aggregated" | "empty" | null
  >(null);

  const [inferenceList, setInferenceList] = useState<Array<[string, Dict]>>([]);

  const [filteredInferenceList, setFilteredInferenceList] = useState<
    Array<[string, Dict]>
  >([]);

  const initializeInference = () => {
    if (!labels) return;
    const entities = inference["entities"];
    let newList: Array<[string, Dict]> = [];
    for (let entID in entities) {
      let ent = entities[entID];
      if (Array.isArray(ent)) {
        if (ent.length === 0) newList = [...newList, [entID, {}]];
        else
          for (let subEnt of ent) {
            newList = [...newList, [entID, subEnt]];
          }
      } else {
        newList = [...newList, [entID, ent]];
      }
    }
    setInferenceList([...newList]);
    if (filter) setFilteredInferenceList(filterInferenceList([...newList]));
    else setFilter("single");
  };

  const searchAggregationLabels = () => {
    let newAggregationLabels: Array<string> = [];
    for (let label in labels) {
      if (labels[label].tag_aggregation) {
        newAggregationLabels = [
          ...newAggregationLabels,
          ...labels[label].tag_aggregation.aggregation_fields,
        ];
      }
    }
    return newAggregationLabels;
  };

  useEffect(() => {
    if (!inference || !labels) return;
    initializeInference();
  }, [inference]);

  const sortByModelOrder = (list: Array<[string, Dict]>) => {
    if (!modelOrder) return list;

    let sortedList: Array<[string, Dict]> = [];
    for (let key of modelOrder) {
      sortedList = [...sortedList, ...list.filter((x) => x[0] === key)];
    }
    return [...new Set([...sortedList, ...list])];
  };

  const filterInferenceList = (iList?: Array<[string, Dict]>) => {
    let toSortList = iList ? iList : inferenceList;
    toSortList = toSortList.sort((a, b) => {
      let aLabel = labels![a[0]].tag_alias;
      let bLabel = labels![b[0]].tag_alias;
      if (aLabel < bLabel) return -1;
      if (aLabel > bLabel) return 1;
      return 0;
    });
    let checkAggregationLabels = searchAggregationLabels();
    switch (filter) {
      case "all":
        return sortByModelOrder(toSortList);
      case "single":
        return sortByModelOrder(
          toSortList.filter(
            (x) => checkAggregationLabels.find((y) => y === x[0]) === undefined
          )
        );
      case "aggregated":
        return toSortList.filter(
          (x) => checkAggregationLabels.find((y) => y === x[0]) !== undefined
        );
      case "empty":
        return sortByModelOrder(
          toSortList.filter((x) => Object.keys(x[1]).length === 0)
        );
      default:
        return toSortList;
    }
  };

  useEffect(() => {
    if (inference.length === 0 && !filter) return;
    setFilteredInferenceList(filterInferenceList());
  }, [filter]);
  const stageContext = useContext(StageContext);
  if (!labels || !inference) return <></>;

  document.getElementById("entitiesList")?.addEventListener("scroll", (e) => {
    if (!stageContext) return;
    if (stageContext.highlighted) stageContext.setHighlighted(null);
  });

  return (
    <>
      {createMode || editField ? (
        <CreateSimpleEntityPanel
          unusedLabels={unusedLabels}
          setCreateMode={setCreateMode}
          createMode={createMode}
          setAddSimpleAnnotationMode={setAddSimpleAnnotationMode}
          handleAddNewAnnotation={handleAddNewAnnotation}
          handleEditAnnotation={handleEditAnnotation}
          handleDeleteAnnotation={handleDeleteAnnotation}
          editField={editField}
          setEditField={setEditField}
          setEditSimpleAnnotation={setEditSimpleAnnotation}
          setAlertData={setAlertData}
        />
      ) : (
        <div className="grid grid-rows-[2.5rem,auto] w-full gap-2">
          <div className="grid grid-cols-[auto,20px] w-full gap-2">
            <Button
              className="!h-10 w-full rounded-myb border-2 !border-[#EAEBFB] flex items-center justify-center cursor-pointer gap-2"
              action={() => setCreateMode(true)}
            >
              Add field{""}
              <AddSimpleAnnotationSVG width={25} height={25} />
            </Button>
            <div id="filterMenu" className="block !m-auto">
              <button
                id="filterMenu"
                onClick={(e) => {
                  let popupDiv = document.getElementById("filterMenu-popup");
                  if (popupDiv?.classList.contains("max-h-20"))
                    popupDiv?.classList.replace("max-h-20", "max-h-0");
                  else popupDiv?.classList.replace("max-h-0", "max-h-20");
                }}
              >
                <FaFilter size={20} className=" pointer-events-none" />
              </button>
            </div>
          </div>
          <div
            id="filterMenu-popup"
            className="max-h-0 !transition-all !duration-300 flex flex-row overflow-hidden gap-2 flex-wrap"
          >
            <FilterPill
              className="text-sm"
              filterItem={"all"}
              filterSelected={filter}
              callbackAction={() => {
                setFilter("all");
              }}
            >
              {" "}
              All{" "}
            </FilterPill>
            <FilterPill
              className="text-sm"
              filterItem={"single"}
              filterSelected={filter}
              callbackAction={() => {
                setFilter("single");
              }}
            >
              {" "}
              Single Fields{" "}
            </FilterPill>
            <FilterPill
              className="text-sm"
              filterItem={"empty"}
              filterSelected={filter}
              callbackAction={() => {
                setFilter("empty");
              }}
            >
              {" "}
              Empty Fields{" "}
            </FilterPill>
            {/*    AGGREGATED FIELDS FILTER 
            aggregationKey && (
              <FilterPill
                className="text-sm"
                filterItem={"aggregated"}
                filterSelected={filter}
                callbackAction={() => {
                  setFilter("aggregated");
                }}
              >
                {" "}
                {aggregationKey} Fields{" "}
              </FilterPill>
            )
              */}
          </div>
        </div>
      )}

      <div
        id="entitiesList"
        className=" h-full px-1 flex flex-col overflow-y-auto overflow-x-hidden w-full gap-5"
      >
        <div id="simple" className="hidden"></div>
        <div
          className="absolute block bg-[#EAEBFB] w-full z-20 h-full !transition-all !duration-150 pointer-events-none"
          style={{
            opacity: editField || createMode ? "0.6" : "0",
          }}
        />
        {filteredInferenceList.map((ent, index) => {
          if (editField?.label === ent[0]) return <></>;
          return (
            <div
              id={ent[0]}
              key={ent[0] + index + ent[1].text}
              className="relative flex flex-row w-full items-center"
            >
              <div
                id={`anchor-${ent[0]}`}
                className="absolute w-1 h-1 top-1/2 pointer-events-none"
                style={{
                  pointerEvents: editField || createMode ? "none" : "auto",
                }}
              />
              <ResultPill
                setCreateMode={setCreateMode}
                label={ent[0]}
                labelKey={labels[ent[0]].tag_alias}
                labelValue={Object.keys(ent[1]).length > 0 ? ent[1].text : null}
                labelId={Object.keys(ent[1]).length > 0 ? ent[1].id : -1}
                confidence={
                  Object.keys(ent[1]).length > 0 ? ent[1].confidence : 0
                }
                bBox={
                  Object.keys(ent[1]).length > 0 ? ent[1].bounding_boxes : []
                }
                setDefaultAddLabel={setDefaultAddLabel}
                setEditField={setEditField}
                pointerDisabled={editField || createMode}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
